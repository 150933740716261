import axios from 'axios'
import { CompanyConfigSchema } from '../models/Schemas'

const env = process.env.NODE_ENV === 'production' ? 'prod' : 'stage'
const dynamoDomain = `https://hapi.xiva-react-chatbot-af.${env}.xibsolutions.com`
// const dynamoDomain = `https://hapi.xiva-react-chatbot-af.prod.xibsolutions.com`

export async function queryCompanyConfig (companyName: string): Promise<any> {
  try {
    const response = await axios.get(`${dynamoDomain}/dynamo/company-config?companyName=${companyName}`)
    // const validationResults = CompanyConfigSchema.validate(response.data)
    // if (validationResults.error != null) {
    //   console.log(validationResults)
    //   throw validationResults.error
    // }
    return response.data
  } catch (originalError) {
    console.error(`Error fetching company config for ${companyName}:`, originalError)
    if (axios.isAxiosError(originalError) && (originalError.config != null)) {
      const error = new Error( // Created a new variable for the error
        `Message: ${JSON.stringify(originalError.message)}\n` +
        `Code: ${JSON.stringify(originalError.code)}\n` +
        `Connection: ${JSON.stringify(originalError.config.url)}\n`
      )
      throw error
    }
    throw originalError
  }
}
