import React, { createContext, useState, useEffect, useRef } from 'react'
import { queryCompanyConfig } from '../../services/dynamodb'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import './Config.css'
import Loader from '../loader/loader'

interface ConfigContextType {
  BASE_API_URL: string;
  features?: any;
  styles?: any;
  cognito?: any;
  properties?: any; 
}

const defaultConfig: ConfigContextType = {
  BASE_API_URL: process.env.NODE_ENV == 'development' ? 'https://hapi.xiva-react-chatbot-af.stage.xibsolutions.com' : 'https://hapi.xiva-react-chatbot-af.prod.xibsolutions.com'
  // BASE_API_URL: 'https://hapi.xiva-react-chatbot-af.prod.xibsolutions.com',
}

const ConfigContext = createContext<ConfigContextType>(defaultConfig)

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(defaultConfig)
  const CLIENT: string = process.env.CLIENT ?? ''
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [errorCode, setErrorCode] = useState('')
  const [isCollapsed, setIsCollapsed] = useState(true)
  const contentRef = useRef<HTMLDivElement | null>(null)

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed)
    if (contentRef.current) {
      if (contentRef.current.style.maxHeight) {
        contentRef.current.style.maxHeight = '';
      } else {
        contentRef.current.style.maxHeight = contentRef.current.scrollHeight + 'px';
      }
    }
  }

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const client = await queryCompanyConfig(CLIENT)
        setConfig(prevConfig => ({ ...prevConfig, ...client }))
        setLoading(false)
      } catch (err) {
        console.error(err)
        setLoading(false)
        setError(err.message)
      }
    }
    fetchConfig()
  }, [])

  if (loading) {
    return <Loader show={loading} />
  }

  if (error) {
    return (
        <div className="error-page">
          <div className="error-content">
            <FontAwesomeIcon icon={faExclamationTriangle} size="3x" />
            <h1>{errorCode} Uh-oh! We're currently experiencing difficulties with <u>{process.env.CLIENT}</u>. Please check back later. </h1>
            <button className="collapsible" onClick={toggleCollapse}>Show more details</button>
            <div className="content" ref={contentRef} >
              <p>{error}</p>
            </div>
          </div>
        </div>
    )
  }

  return (
      <ConfigContext.Provider value={config}>
        {children}
      </ConfigContext.Provider>
  )
}

export default ConfigContext
